import React, { useEffect, useState } from "react";
import ApprovalTable from "../components/approval_table";
import { useOktaAuth } from "@okta/okta-react";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

export default function Approver() {
  const { oktaAuth, authState } = useOktaAuth();
  const [accessToken, setAccessToken] = useState(null);
  const history = useHistory();

  const handleButtonClick = () => {
    // Navigate to "/approvals"
    history.push("/");
  };
  useEffect(() => {
    if (authState?.isAuthenticated) {
      // Function to fetch user info
      const fetchUserInfo = async () => {
        try {
          const token = await oktaAuth.getAccessToken();
          setAccessToken(token);
        } catch (err) {
          console.error("Error getting user info:", err);
        }
      };

      // Call the function to fetch user info
      fetchUserInfo();
    }
  }, [authState, oktaAuth]);

  return (
    <div>
      <div className="homeButton">
        <Button
          variant="filled"
          color="primary"
          style={{
            background: "#003366", // Set background color to blue
            color: "white", // Set text color to white
            marginRight: "1%",
            marginTop: "0.5%",
            textTransform: window.innerWidth <= 600 ? "capitalize" : "none",
          }}
          onClick={handleButtonClick}
        >
          <ShortcutIcon />
          Go Back
        </Button>
      </div>

      {accessToken !== null && <ApprovalTable accessToken={accessToken} />}
    </div>
  );
}
