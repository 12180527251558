export const isApprover = async (accessToken) => {
  try {
    const response = await fetch(`/api/v1/isApprover`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error checking approver:", error);
    throw error;
  }
};

export const getDepartments = async (accessToken) => {
  try {
    const response = await fetch(`/api/v1/retrieve-own-departments`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getOwnRequisitions = async (accessToken) => {
  try {
    // Fetch requisitions data from the backend API
    const response = await fetch(`/api/v1/retrieve-own-requisitions`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (!response.ok) {
      throw new Error(`Error fetching requisitions: ${response.statusText}`);
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
    // Handle error
  }
};

export const submitRequisition = async (accessToken, requestBody) => {
  try {
    const response = await fetch(`/api/v1/submit-requisition`, {
      method: "POST", // Add method: "POST" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getPendingRequisitions = async (accessToken) => {
  try {
    const response = await fetch(`/api/v1/pending-requisitions`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createDecision = async (accessToken, row) => {
  try {
    const response = await fetch(`/api/v1/create-decision`, {
      method: "POST", // Add method: "POST" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(row),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changeStatus = async (accessToken, changeStatusBody) => {
  try {
    const response = await fetch(`/api/v1/change-status`, {
      method: "POST", // Add method: "POST" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(changeStatusBody),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getApprovalHistory = async (accessToken) => {
  try {
    const response = await fetch(`/api/v1/approval-history`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRequisitionStatus = async (accessToken, id) => {
  try {
    const statusResponse = await fetch(`/api/v1/retrieve-status/${id}`, {
      method: "GET", // Add method: "GET" here
      headers: {
        "Content-Type": "application/json", // Specify Content-Type header
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const statusDataResponse = await statusResponse.json();
    return statusDataResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getApproverDepartments = async (accessToken, id) => {
  try {
    const statusResponse = await fetch(
      `/api/v1/retrieve-approver-departments`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const statusDataResponse = await statusResponse.json();
    return statusDataResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
