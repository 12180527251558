import './App.css';
import React, { useEffect } from 'react';
import NavBar from './components/nav-bar';
import oktaAuth from './okta-auth';
import Requisitions from './pages/requisitions';
import CustomLoginCallback from './pages/CustomLoginCallback';
import { Security, SecureRoute, useOktaAuth } from '@okta/okta-react';
import {
  Route,
  Switch,
  useHistory,
  BrowserRouter,
} from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import Approver from './pages/approver';

function AppContent() {
  const oktaState = useOktaAuth();
  useEffect(() => {
    
  }, [oktaState, oktaState?.authState]);
  

  return (

    <div>
      <NavBar/>
      <BrowserRouter>
      <Switch>
        <Route path="/login/callback" component={CustomLoginCallback}/>
        <SecureRoute path="/" component={Requisitions} exact/>
        <SecureRoute path="/approvals" component={Approver}/>
        
      </Switch>
      </BrowserRouter>

    </div>
      
  );
}

export default function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    window.location.reload(false);
  };

  return (
    <Security oktaAuth={oktaAuth}
              restoreOriginalUri={restoreOriginalUri}
    >
      <AppContent />
    </Security>
  );
}
