import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import styleded from "styled-components";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import ModalRequisitions from "./modal_requisition";
import CheckIcon from "@mui/icons-material/Check";
import { useOktaAuth } from "@okta/okta-react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import CircularProgress from "@mui/material/CircularProgress";
import { isApprover, getDepartments, getOwnRequisitions } from "../api/api";

function extractDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
}
const euroFormatter = new Intl.NumberFormat("en-EU", {
  style: "currency",
  currency: "EUR",
});

const InputContainer = styleded.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40em;
    margin: 1%;
    `;

const VISIBLE_FIELDS = [
  "id",
  "Date",
  "Description",
  "Vendor",
  "Amount",
  "VAT Included",
  "Status",
];
const columns = VISIBLE_FIELDS.map((field) => {
  let columnConfig = {
    field,
    headerName: field === "id" ? "ID" : field,

    renderCell: (params) => {
      if (field === "Status") {
        const statusStyle = {
          fontWeight: "bold",
        };

        if (params.value === "Approved") {
          statusStyle.backgroundColor = "green";
          statusStyle.color = "white";
          statusStyle.padding = "5px";
        } else if (params.value === "Denied") {
          statusStyle.backgroundColor = "red";
          statusStyle.color = "white";
          statusStyle.padding = "5px";
        }

        return <span style={statusStyle}>{params.value}</span>;
      } else if (field === "VAT Included") {
        // Render CheckIcon for true and nothing for false
        return params.value ? <CheckIcon /> : null;
      }

      return params.value;
    },
  };

  // Apply flex: 1 only if the field is 'Description'
  if (field === "Description") {
    columnConfig.flex = 1;
  }
  if (window.innerWidth <= 600) {
    columnConfig.minWidth = 400;
  }

  return columnConfig;
});

export default function RequisitionsDisplay({ accessToken }) {
  const { authState } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approver, setApprover] = useState(false);
  const [departments, setDepartments] = useState([]);
  const history = useHistory();

  const handleButtonClick = () => {
    // Navigate to "/approvals"
    history.push("/approvals");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const myData = [
    {
      id: 11,
      Date: "2023-11-09",
      Description: "Item 1",
      Vendor: "Vendor A",
      Amount: 100,
      "VAT Included": true,
      Status: "Approved",
    },
    // Add more data as needed
  ];

  useEffect(() => {
    // Set loading state to true
    if (authState?.isAuthenticated) {
      const checkApprover = async () => {
        const responseData = await isApprover(accessToken);
        const hasMatchingApprover = responseData.data.isApprover;
        if (hasMatchingApprover) {
          setApprover(true);
        }
      };

      // Call the async function
      checkApprover();
      const fetchDepartments = async () => {
        const responseData = await getDepartments(accessToken);
        const totalRows = responseData.data;
        const departmentsArray = totalRows.map((row) => {
          const departmentName = row["department"];
          return {
            id: row["recordID"],
            department: departmentName.includes("VP") ? "VP" : departmentName,
          };
        });
        setDepartments(departmentsArray);
      };
      fetchDepartments();

      try {
        const loadTable = async () => {
          setLoading(true);

          const responseData = await getOwnRequisitions(accessToken);
          const totalRows = responseData.data; // Assuming the data structure matches the expected format
          // Format the rows
          const formattedRows = totalRows.map((row) => {
            const isApproved = row["finalApproval"];
            const isDenied = row["denied"];
            const approverName = row["approverName"];
            let status = "";

            if (isApproved) {
              status = "Approved";
            } else if (isDenied) {
              status = "Denied";
            } else if (approverName !== "") {
              status = approverName;
            }

            return {
              id: row["recordID"],
              Date: extractDateFromTimestamp(row["dateCreated"]),
              Description: row["description"],
              Vendor: row["vendor"],
              Amount: euroFormatter.format(row["amount"]),
              "VAT Included": row["vat"],
              Status: status,
              Approved: isApproved,
              Denied: isDenied,
              approverName: approverName,
            };
          });

          // Update the state with the formatted rows
          setTableData(formattedRows);

          setLoading(false);
        };

        loadTable();
      } catch (error) {
        console.log(error);
      }
    }
  }, [open]);
  return (
    <div>
      <div className="bttns">
        <Button
          onClick={handleOpen}
          className="muiButton"
          variant="filled"
          color="primary"
          style={{
            background: "#003366",
            color: "white",
            marginLeft: "1%",
            marginTop: "0.5%",
            textTransform: window.innerWidth <= 600 ? "capitalize" : "none",
          }}
        >
          <AddIcon /> Add Requisition
        </Button>
        {approver && (
          <Button
            variant="filled"
            className="muiButton"
            color="primary"
            style={{
              background: "#003366",
              color: "white",
              marginRight: "1%",
              marginTop: "0.5%",
              textTransform: window.innerWidth <= 600 ? "capitalize" : "none",
            }}
            onClick={handleButtonClick}
          >
            <ShortcutIcon />
            Go to Approvals
          </Button>
        )}
      </div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            height: 400,
            margin: "1%",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
          }}
        >
          <DataGrid
            rows={tableData}
            localeText={{
              noRowsLabel: loading ? (
                <CircularProgress />
              ) : (
                "No Requisitions Submitted"
              ),
            }}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
          />
        </Box>
      </div>
      {open && accessToken !== null && (
        <ModalRequisitions
          handleClose={handleClose}
          departments={departments}
          accessToken={accessToken}
        />
      )}
    </div>
  );
}
