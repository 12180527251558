import { OktaAuth } from '@okta/okta-auth-js';

const configDev = {
  issuer: 'https://auth-test.slu.edu/oauth2/default',
  clientId: '0oa73bmp9tlqVwMNl0x7',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: 'http://localhost:3000/',
};

const configProd = {
  issuer: 'https://auth.slu.edu/oauth2/default',
  clientId: '0oannw212p4WZMuG5297',
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  postLogoutRedirectUri: 'https://requisitions.madrid.slu.edu/',
}


const isProduction = process.env.NODE_ENV === 'production';

// Select the appropriate configuration
const selectedConfig = isProduction ? configProd : configDev;

const oktaAuth = new OktaAuth(selectedConfig);

export default oktaAuth;