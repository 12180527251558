import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import HistoryDisplay from "./approval_history";
import {
  getPendingRequisitions,
  createDecision,
  changeStatus,
  getApproverDepartments,
} from "../api/api";

function extractDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
}

const euroFormatter = new Intl.NumberFormat("en-EU", {
  style: "currency",
  currency: "EUR",
});

export default function ApprovalDisplay({ accessToken }) {
  const { oktaAuth, authState } = useOktaAuth();
  const [tableData, setTableData] = useState([]);
  const [approveClicked, setApproveClicked] = useState(false);
  const [denyClicked, setDenyClicked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);
  const [approverDepartments, setApproverDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  useEffect(() => {
    const fetchApproverDepartments = async () => {
      const responseData = await getApproverDepartments(accessToken);
      const totalRows = responseData.data;
      const formattedRows = totalRows.map((row) => {
        return row.department;
      });
      setApproverDepartments(formattedRows);
    };
    const fetchPendingRequisitions = async () => {
      const responseData = await getPendingRequisitions(accessToken);
      const totalRows = responseData.data;

      const formattedRows = totalRows
        .filter(
          (row) =>
            row["approve"] === "" && !row["finalApproval"] && !row["denied"]
        )
        .map((row) => ({
          id: row["recordID"],
          Date: extractDateFromTimestamp(row["dateCreated"]),
          Requester: row["requesterName"],
          Description: row["description"],
          Vendor: row["vendor"],
          Amount: euroFormatter.format(row["amount"]),
          "VAT Included": row["vat"],
          approverBanner: row["approverBannerID"],
          approverName: row["approverName"],
          requesterBanner: row["requesterBannerID"],
          Department: row["approver"],
        }));

      setTableData(formattedRows);
    };
    const loadData = async () => {
      setLoading(true);
      await fetchApproverDepartments();
      await fetchPendingRequisitions();
      setLoading(false);
    };
    loadData();
  }, [authState, approveClicked, denyClicked, accessToken]);
  useEffect(() => {
    if (approverDepartments.length === 1) {
      setSelectedDepartment(approverDepartments[0]);
    }
  }, [approverDepartments]);

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(id)) {
        return prevSelectedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const handleApproveButtonClick = async (row) => {
    setApproveClicked(true);

    const changeStatusBody = {
      rowid: row.id,
      status: true,
    };

    if (selectedRows.length === 0) {
      try {
        row.Decision = "Approved";
        await createDecision(accessToken, row);

        await changeStatus(accessToken, changeStatusBody);
      } catch (error) {
        console.error("Error approving row:", error);
      }

      setUpdateKey((prevKey) => prevKey + 1);
      setSelectedRows([]);
      setApproveClicked(false);
      return;
    }

    // Handle the click for all selected rows
    for (const id of selectedRows) {
      const selectedRow = tableData.find((r) => r.id === id);
      if (selectedRow) {
        try {
          changeStatusBody.rowid = selectedRow.id;
          selectedRow.Decision = "Approved";
          await createDecision(accessToken, selectedRow);

          await changeStatus(accessToken, changeStatusBody);
        } catch (error) {
          console.error("Error approving row:", error);
        }
      }
    }

    setUpdateKey((prevKey) => prevKey + 1);
    setSelectedRows([]);
    setApproveClicked(false);
  };

  const handleDenyButtonClick = async (row) => {
    setDenyClicked(true);
    const changeStatusBody = {
      rowid: row.id,
      status: false,
    };

    if (selectedRows.length === 0) {
      try {
        row.Decision = "Denied";
        await createDecision(accessToken, row);

        await changeStatus(accessToken, changeStatusBody);
      } catch (error) {
        console.error("Error denying row:", error);
      }

      setUpdateKey((prevKey) => prevKey + 1);
      setSelectedRows([]);
      setDenyClicked(false);
      return;
    }

    // Handle the click for all selected rows
    for (const id of selectedRows) {
      const selectedRow = tableData.find((r) => r.id === id);
      if (selectedRow) {
        try {
          changeStatusBody.rowid = selectedRow.id;

          selectedRow.Decision = "Denied";
          await createDecision(accessToken, selectedRow);

          await changeStatus(accessToken, changeStatusBody);
        } catch (error) {
          console.error("Error denying row:", error);
        }
      }
    }

    setUpdateKey((prevKey) => prevKey + 1);
    setSelectedRows([]);
    setDenyClicked(false);
  };
  const filteredTableData = tableData.filter(
    (row) => row.Department === selectedDepartment
  );

  return (
    <div>
      <div
        className="approval-table-title"
        style={{
          margin: "1%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="tableTitle">Pending Approvals</p>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel id="select-department-label">
            Approver Department
          </InputLabel>
          <Select
            labelId="select-department-label"
            id="select-department"
            value={selectedDepartment || ""}
            label="Approver Department"
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            {approverDepartments.map((department, index) => (
              <MenuItem key={index} value={department}>
                {department}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ width: "100%" }}>
        <TableContainer
          component={Paper}
          style={{ width: "auto", margin: "1%", maxHeight: 400 }}
        >
          <Table
            sx={{ minWidth: 650, backgroundColor: "white" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>VAT Included</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && selectedDepartment === "" && (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <p style={{ color: "red" }}>
                      Please select the Department that you will be approving
                      from
                    </p>
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
              filteredTableData.length === 0 &&
              selectedDepartment !== "" ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No pending Approvals
                  </TableCell>
                </TableRow>
              ) : loading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                filteredTableData.map((row) => (
                  <TableRow
                    key={row.id}
                    selected={selectedRows.includes(row.id)}
                    style={{ cursor: "pointer" }}
                    onChange={() => handleCheckboxChange(row.id)}
                  >
                    <TableCell>
                      <Checkbox
                        color="primary"
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                        onClick={() => handleCheckboxChange(row.id)}
                      />
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.Date}</TableCell>
                    <TableCell>{row.Requester}</TableCell>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell>{row.Vendor}</TableCell>
                    <TableCell>{row.Amount}</TableCell>
                    <TableCell>
                      {row["VAT Included"] ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell>
                      {(selectedRows.includes(row.id) ||
                        selectedRows.length === 0) && (
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          onClick={() => handleApproveButtonClick(row)}
                          disabled={approveClicked || denyClicked}
                        >
                          {approveClicked ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Approve"
                          )}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      {(selectedRows.includes(row.id) ||
                        selectedRows.length === 0) && (
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => handleDenyButtonClick(row)}
                          disabled={approveClicked || denyClicked}
                        >
                          {denyClicked ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Deny"
                          )}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />
      <p className="tableTitle">Approval History</p>
      <HistoryDisplay accessToken={accessToken} updateKey={updateKey} />
    </div>
  );
}
