import React, { useState, useEffect } from "react";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import styleded from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import { useOktaAuth } from "@okta/okta-react";
import Box from "@mui/material/Box";
import { getApprovalHistory, getRequisitionStatus } from "../api/api";
function extractDateFromTimestamp(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}-${month}-${year}`;
}
const euroFormatter = new Intl.NumberFormat("en-EU", {
  style: "currency",
  currency: "EUR",
});

const InputContainer = styleded.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40em;
    margin: 1%;
    `;

const VISIBLE_FIELDS = [
  "Requisition ID",
  "Date",
  "Requester",
  "Description",
  "Vendor",
  "Amount",
  "VAT Included",
  "Status",
];
const columns = VISIBLE_FIELDS.map((field) => {
  let columnConfig = {
    field,
    headerName: field,
    renderCell: (params) => {
      if (field === "Status") {
        return <span dangerouslySetInnerHTML={{ __html: params.value }} />;
      } else if (field === "VAT Included") {
        // Render CheckIcon for true and nothing for false
        return params.value ? <CheckIcon /> : null;
      }
      if (window.innerWidth <= 600) {
        columnConfig.minWidth = 400;
      }

      return params.value;
    },
  };

  // Apply flex: 1 only if the field is 'Description'
  if (field === "Description") {
    columnConfig.flex = 1;
  }
  if (window.innerWidth <= 600) {
    columnConfig.minWidth = 400;
  }

  return columnConfig;
});

export default function HistoryDisplay({ accessToken }) {
  const { authState } = useOktaAuth();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      try {
        const loadTable = async () => {
          setLoading(true);

          const responseData = await getApprovalHistory(accessToken);
          const totalRows = responseData.data;

          const formattedRows = [];
          const requisitionIds = new Set();

          for (const row of totalRows) {
            try {
              const id = row["requisitionID"];
              const statusDataResponse = await getRequisitionStatus(
                accessToken,
                id
              );
              const status = statusDataResponse.data[0]["status"];
              const formattedRow = {
                id: row["recordID"],
                "Requisition ID": row["requisitionID"],
                Date: extractDateFromTimestamp(row["dateCreated"]),
                Description: row["description"],
                Vendor: row["vendor"],
                Amount: euroFormatter.format(row["amount"]),
                "VAT Included": row["vat"],
                Requester: row["requester"],
                Status: status,
              };

              if (!requisitionIds.has(formattedRow["Requisition ID"])) {
                requisitionIds.add(formattedRow["Requisition ID"]);
                formattedRows.push(formattedRow);
              }
            } catch (error) {
              console.error(
                "Error fetching status for Requisition ID:",
                row[14]["value"],
                error
              );
              formattedRows.push({
                id: row[3]["value"],
                "Requisition ID": row[14]["value"],
                Date: extractDateFromTimestamp(row[1]["value"]),
                Description: row[7]["value"],
                Vendor: row[8]["value"],
                Amount: euroFormatter.format(row[9]["value"]),
                "VAT Included": row[10]["value"],
                Requester: row[6]["value"],
                Status: "Error",
              });
            }
          }
          setTableData(formattedRows);

          setLoading(false);
        };

        loadTable();
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessToken]);
  return (
    <div>
      <div style={{ width: "100%" }}>
        <Box
          sx={{
            height: 400,
            width: "fitContent",
            margin: "1%",
            boxShadow: "0 0 20px rgba(0, 0, 0, 0.15)",
          }}
        >
          <DataGrid
            rows={tableData}
            localeText={{
              noRowsLabel: loading ? (
                <CircularProgress />
              ) : (
                "No Requisitions Submitted"
              ),
            }}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 1,
              },
            }}
          />
        </Box>
      </div>
    </div>
  );
}
