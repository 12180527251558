import * as React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";

const OktaError = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}: {error.message}
      </p>
    );
  }
  return <p>Error: {error.toString()}</p>;
};

let handledRedirect = false;

const CustomLoginCallback = ({
  errorComponent,
  loadingElement = <p>Loading ...</p>,
  onAuthResume,
}) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = React.useState(null);
  const history = useHistory();

  const ErrorReporter = errorComponent || OktaError;

  React.useEffect(() => {
    const isInteractionRequired =
      oktaAuth.idx.isInteractionRequired ||
      oktaAuth.isInteractionRequired.bind(oktaAuth);
    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    if (!handledRedirect) {
      oktaAuth
        .handleLoginRedirect()
        .then(() => {
          history.replace("/");
        })
        .catch((e) => {
          setCallbackError(e);
        });
      handledRedirect = true;
    }
  }, [oktaAuth, onAuthResume, history]);

  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};

export default CustomLoginCallback;
