import React, { useEffect, useState } from "react";
import RequisitionsDisplay from "../components/submit_requisition";
import { useOktaAuth } from "@okta/okta-react";

export default function Requisitions() {
  const { oktaAuth, authState } = useOktaAuth();
  const [accessToken, setAccessToken] = useState(null);
  useEffect(() => {
    // Function to fetch user info
    const fetchUserInfo = async () => {
      try {
        const token = await oktaAuth.getAccessToken();
        setAccessToken(token);
      } catch (err) {
        console.error("Error getting user info:", err);
      }
    };

    // Call the function to fetch user info
    fetchUserInfo();
  }, [authState, oktaAuth]);

  return (
    <div>{accessToken !== null && <RequisitionsDisplay accessToken={accessToken} />}</div>
  );
}
