import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { submitRequisition } from "../api/api";

export default function ModalRequisitions({
  handleClose,
  departments,
  accessToken,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setdescription] = useState("");
  const [vendor, setVendor] = useState("");
  const [alert, setAlert] = useState(null);
  const [amount, setAmount] = useState("");
  const [isVATIncluded, setIsVATIncluded] = useState(false);
  const [departmentID, setDepartmentID] = useState("");

  const [open, setOpen] = useState(false);

  const handleDepartmentChange = (event) => {
    setDepartmentID(event.target.value);
  };

  const handleDepartmentClose = () => {
    setOpen(false);
  };

  const handleDepartmentOpen = () => {
    setOpen(true);
  };

  const handleVATIncludedChange = () => {
    setIsVATIncluded((prevState) => !prevState);
  };

  const handledescriptionChange = (event) => {
    setdescription(event.target.value);
  };

  const handleVendorChange = (event) => {
    setVendor(event.target.value);
  };
  const handleAmountChange = (event) => {
    const value = event.target.value;
    const regex = /^\d*\.?\d{0,2}$/;

    if (regex.test(value)) {
      setAmount(value);
    }
  };

  useEffect(() => {
    if (departments.length === 1) {
      setDepartmentID(departments[0].id);
    }
  }, [departments]);

  useEffect(() => {
    if (alert) {
      // Automatically clear the alert after 4 seconds
      const timer = setTimeout(
        () => {
          if (alert?.props.severity === "success") {
            handleClose();
          }

          setAlert(null);
        },
        alert?.props.severity === "success" ? 1500 : 4000
      );

      // Cleanup the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const handleSubmit = async () => {
    let response = null;

    try {
      setIsSubmitting(true);
      if (!description || !vendor || !amount || departmentID === "") {
        setAlert(
          <Alert severity="warning">
            <AlertTitle>Error</AlertTitle>
            Please fill out all required fields (Department,Description, Vendor,
            and Amount).
          </Alert>
        );
        return;
      }
      if (amount <= 0) {
        setAlert(
          <Alert severity="warning">
            <AlertTitle>Error</AlertTitle>
            Amount should be greater than 0.
          </Alert>
        );
        return;
      }

      const requestBody = {
        description,
        vendor,
        amount,
        vat: isVATIncluded,
        departmentID,
      };

      const responseData = await submitRequisition(accessToken, requestBody);

      if (responseData.status === 200) {
        setDepartmentID("");
        setdescription("");
        setVendor("");
        setAmount("");
        setIsVATIncluded(false);

        setAlert(
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Requisition submitted successfully!
          </Alert>
        );
      } else {
        setAlert(
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Failed to submit requisition
          </Alert>
        );
      }
    } catch (error) {
      setAlert(
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Failed to submit requisition
        </Alert>
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        open={true} // You can use a prop to control the modal's open state
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            height: "auto",
          }}
          className="box"
        >
          <FormControl sx={{ marginBottom: "5%" }}>
            <InputLabel id="demo-controlled-open-select-label">
              Select Department
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleDepartmentClose}
              onOpen={handleDepartmentOpen}
              value={departmentID || ""}
              label="Select Department"
              onChange={handleDepartmentChange}
            >
              {departments.map((dept) => (
                <MenuItem key={dept.id} value={dept.id}>
                  {dept.department}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-multiline-static"
            className="description"
            label="Description"
            variant="outlined"
            multiline
            maxRows={9}
            value={description}
            onChange={handledescriptionChange}
            sx={{ marginBottom: "5%" }}
            InputProps={{
              sx: { height: 250, alignItems: "flex-start", overflow: "auto" },
            }}
          />

          <TextField
            id="outlined-basic-vendor"
            className="vendor"
            label="Vendor"
            variant="outlined"
            value={vendor}
            onChange={handleVendorChange}
            sx={{ marginBottom: "5%" }}
          />
          <TextField
            label="Amount"
            value={amount}
            onChange={handleAmountChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
              inputProps: { inputMode: "decimal", pattern: "[0-9]*" },
            }}
            variant="outlined"
            sx={{ marginBottom: "5%" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isVATIncluded}
                onChange={handleVATIncludedChange}
              />
            }
            label="VAT included"
          />

          <button
            className="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? <CircularProgress color="inherit" /> : "Submit"}
          </button>

          <Stack sx={{ width: "100%", marginTop: "5%" }} spacing={2}>
            {alert && alert}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
